import { useMemo, forwardRef, ReactNode, Ref } from 'react';
import cx from 'classnames';

import Tiles from 'components/common/Tiles';
import style from './FormInput.module.css';

type FormRadioGroupValue = string | number | boolean;

type FormRadioGroupProps = {
  options: {
    label: ReactNode;
    value: FormRadioGroupValue;
    disabled?: boolean;
    suffix?: ReactNode;
  }[];
  onChange: (value: FormRadioGroupValue) => void;
  name?: string;
  value?: FormRadioGroupValue;
  status?: 'default' | 'error' | 'warning' | 'success' | 'info';
  readOnly?: boolean;
  disabled?: boolean;
  columns?: number;
  spacing?: 'none' | 'xs' | 'sm' | 'md' | 'lg';
  hideBorder?: boolean;
  isRequired?: boolean;
};

const FormRadioGroup = forwardRef(
  (
    {
      options,
      onChange,
      name = null,
      value = '',
      readOnly = false,
      disabled = false,
      status = 'default',
      columns = 1,
      spacing = 'xs',
      hideBorder = false,
      isRequired = false,
    }: FormRadioGroupProps,
    ref: Ref<HTMLDivElement>
  ) => {
    const state = useMemo(() => {
      if (disabled) return 'disabled';
      if (readOnly) return 'readonly';
      return status;
    }, [disabled, readOnly, status]);

    const canInteract = !['disabled', 'readonly'].includes(state);

    return (
      <Tiles columns={columns} spacing={spacing} ref={ref}>
        {options.map((option) => {
          const isChecked = option.value === value;
          const updateValue = !isRequired && isChecked ? '' : option.value;

          return (
            <label
              key={`${option.value}`}
              className={cx(
                'flex items-center py-2 px-3',

                {
                  'cursor-pointer': canInteract && !option.disabled,
                  'border-none': hideBorder,
                  'border border-gray-300 hover:border-gray-300 rounded-md': option.disabled,
                },
                !option.disabled && style.base,
                canInteract && !option.disabled && isChecked && !hideBorder
                  ? style.success
                  : option.disabled
                    ? null
                    : style[state]
              )}
            >
              <input
                name={name}
                id={name}
                type={isRequired ? 'radio' : 'checkbox'}
                className="hidden"
                value={value as string}
                onChange={!option.disabled ? () => onChange(updateValue) : () => {}}
              />
              <div className="flex-1 flex items-center justify-between">
                <div className={cx('flex items-center', { 'opacity-50': option.disabled })}>
                  <span
                    className={cx(
                      'block h-4 w-4 rounded-full shrink-0 transition-all duration-200 mr-3',
                      {
                        'border border-gray-500': !isChecked,
                        'border-5 border-green-500': isChecked,
                      }
                    )}
                  />
                  <p>{option.label}</p>
                </div>
                {option.suffix}
              </div>
            </label>
          );
        })}
      </Tiles>
    );
  }
);

FormRadioGroup.displayName = 'FormRadioGroup';

export default FormRadioGroup;
