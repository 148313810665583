import { useMemo, forwardRef, type Ref, type ComponentProps } from 'react';
import {
  faExclamationTriangle,
  faInfoCircle,
  faCheckCircle,
  faExclamationCircle,
} from '@fortawesome/pro-regular-svg-icons';
import CurrencyInput from 'react-currency-input-field';

import cx from '@/lib/cx';

import FormInput, { renderAppendedInputNode } from './FormInput';
import style from './FormInput.module.css';

type FormNumberInputProps = {
  value?: string | number;
  onChange: (newValue: string | null) => void;
} & Pick<ComponentProps<typeof FormInput>, 'status' | 'prefix' | 'suffix'> &
  Omit<ComponentProps<typeof CurrencyInput>, 'onChange' | 'onValueChange' | 'value'>;

const FormNumberInput = forwardRef(
  (
    {
      status = 'default',
      disabled,
      readOnly,
      prefix,
      suffix,
      allowDecimals,
      decimalsLimit = 1,
      onChange,
      className,
      ...props
    }: FormNumberInputProps,
    ref: Ref<HTMLInputElement>
  ) => {
    const state = useMemo(() => {
      if (disabled) return 'disabled';
      if (readOnly) return 'readonly';
      return status;
    }, [disabled, readOnly, status]);

    const statusIcon = useMemo(() => {
      if (state === 'error') return faExclamationCircle;
      if (state === 'warning') return faExclamationTriangle;
      if (state === 'success') return faCheckCircle;
      if (state === 'info') return faInfoCircle;
      return null;
    }, [state]);

    return (
      <div className={cx('flex items-center', style.base, style[state])}>
        {prefix && (
          <div className={cx('shrink-0 pl-3', style.statusColor)}>
            {renderAppendedInputNode(prefix)}
          </div>
        )}
        <CurrencyInput
          className={cx(
            'placeholder-gray-500 focus:outline-none w-full py-2 px-4 mr-2 bg-transparent',
            className
          )}
          disabled={disabled}
          readOnly={readOnly}
          allowDecimals={allowDecimals}
          decimalsLimit={decimalsLimit}
          onValueChange={(newValue) => onChange(newValue ?? null)}
          ref={ref}
          {...props}
        />
        {(statusIcon ?? suffix) && (
          <div className={cx('shrink-0 pr-3', style.statusColor)}>
            {renderAppendedInputNode(statusIcon ?? suffix)}
          </div>
        )}
      </div>
    );
  }
);

FormNumberInput.displayName = 'FormNumberInput';

export default FormNumberInput;
