import { useDeepCompareMemo } from 'use-deep-compare';
import * as yup from 'yup';

import config from 'config';
import { makeRequired, makeNullable, phone, date, number, currency } from 'lib/validators';
import getAddressFieldsConfig from 'lib/getAddressFieldsConfig';
import FormFieldGroup from 'components/form/FormFieldGroup';
import FormCustomQuestionField from 'components/form/FormCustomQuestionField';

const defaultGetFieldName = ({ id }) => `question:${id}`;

const useCustomQuestionFields = ({
  validator = null,
  questions = [],
  values = {},
  getFieldName = defaultGetFieldName,
}) => {
  // overwrite isRequired if validator is passed
  const getValidator = (question) => {
    if (!validator) return question.isRequired;
    return validator === 'required';
  };
  const mapped = questions.map((q) => ({ ...q, isRequired: getValidator(q) }));

  // Create a map of any address fields
  const addressMap = useDeepCompareMemo(
    () =>
      mapped.reduce((map, question) => {
        if (question.type !== 'address') return map;

        const fieldName = getFieldName(question);
        return {
          ...map,
          [question.id]: getAddressFieldsConfig({
            fieldName,
            validator: validator ?? (question.isRequired ? 'required' : 'optional'),
            values: values[fieldName],
          }),
        };
      }, {}),
    [mapped, values, getFieldName]
  );

  const validationRules = useDeepCompareMemo(() => {
    const fields = {};

    mapped.forEach((question) => {
      const modifier = question.isRequired ? makeRequired : makeNullable;
      const fieldName = getFieldName(question);

      switch (question.type) {
        case 'phone': {
          fields[fieldName] = modifier(phone);
          break;
        }

        case 'address': {
          fields[fieldName] = addressMap[question.id].validationRules[fieldName];
          break;
        }

        case 'date': {
          fields[fieldName] = modifier(date);
          break;
        }

        case 'gender': {
          fields[fieldName] = modifier(
            yup.object().shape({
              value: modifier(yup.string()),
              other: yup.string(),
            })
          );
          break;
        }

        case 'number': {
          fields[fieldName] = modifier(number);
          break;
        }

        case 'currency': {
          fields[fieldName] = modifier(currency);
          break;
        }

        case 'waiver_simple': {
          fields[fieldName] = question.isRequired
            ? yup.bool().oneOf([true], config('/validationMessages/required'))
            : makeNullable(yup.bool());
          break;
        }

        default: {
          fields[fieldName] = modifier(yup.string());
        }
      }
    });

    return fields;
  }, [addressMap, mapped, getFieldName]);

  const defaultValues = useDeepCompareMemo(() => {
    const defaults = {};

    mapped.forEach((question) => {
      const fieldName = getFieldName(question);

      switch (question.type) {
        case 'address': {
          defaults[fieldName] =
            values[fieldName] ?? addressMap[question.id].defaultValues[fieldName];
          break;
        }

        case 'gender': {
          defaults[fieldName] = {
            value: values[fieldName]?.value ?? '',
            other: values[fieldName]?.other ?? '',
          };
          break;
        }

        case 'waiver_simple': {
          defaults[fieldName] = values[fieldName] ?? false;
          break;
        }

        default: {
          defaults[fieldName] = values[fieldName] ?? '';
        }
      }
    });

    return defaults;
  }, [addressMap, getFieldName, mapped, values]);

  const fieldNodes = useDeepCompareMemo(() => {
    if (mapped.length === 0) return null;
    return (
      <FormFieldGroup>
        {mapped.map((question) => (
          <FormCustomQuestionField key={question.id} {...question} />
        ))}
      </FormFieldGroup>
    );
  }, [mapped]);

  return {
    validationRules,
    defaultValues,
    fieldNodes,
  };
};

export default useCustomQuestionFields;
